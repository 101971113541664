import React, { Component } from 'react';
import axios from "axios";
import {APIURL} from "../../config/config";
import NumberFormat from "react-number-format";


// แสดงใน AgentList
class AgentCarSell extends Component{
    constructor (props) {
        super(props)
        this.state = {
            agentCars: [],
            agentSum: 0
        }

        this.loadAgentCarByAgentId = this.loadAgentCarByAgentId.bind(this)

    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.agent_id !== this.props.agent_id) {
            let agent_id =  nextProps.agent_id
            this.loadAgentCarByAgentId(agent_id)
        }
    }


    componentDidMount() {
        let agent_id =  this.props.agent_id
        this.loadAgentCarByAgentId(agent_id)
    }

    loadAgentCarByAgentId(agent_id) {
        axios.get( APIURL + '/agent/cars/'+agent_id).then(res => {
            let agentSum = 0
            if(res.data.length > 0) {
                agentSum =  res.data.reduce((acc, next) => acc + next.car_external_commission, 0)
            }

            this.setState({
                agentCars: res.data,
                agentSum: agentSum
            })
        }).catch(error => {

        })
    }

    render() {
        const { agentCars } = this.state

        return (
            <div>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">ข้อมูลรถ</th>
                        <th scope="col">ค่านายหน้า</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        agentCars.map((car, index) => (
                            <tr key={index}>
                                <td>
                                    {index+1}
                                </td>
                                <td>
                                    {car.car_brand_name}{' '}{car.car_model_name} ปี {car.car_year_name} สี {car.car_color_name}
                                   <br/>
                                    {
                                        car.car_license_plate_new === '' ? null : (
                                            <>
                                                ทะเบียนใหม่ : {car.car_license_plate_new}&nbsp; จ.{car.province_name_new}
                                            </>
                                        )
                                    }

                                    {
                                        car.car_license_plate_new !== '' && car.car_license_plate_old !== '' && car.car_license_plate_new !== car.car_license_plate_old ? <br/> : null
                                    }
                                    {
                                        car.car_license_plate_new !== car.car_license_plate_old && car.car_license_plate_old !== '' ? (
                                                <>
                                                    ทะเบียนเดิม : {car.car_license_plate_old} &nbsp;จ.{car.province_name_old}
                                                </>
                                            )
                                            : null
                                    }
                                </td>

                                <td>
                                    <NumberFormat
                                        value={car.car_external_commission}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'฿'}
                                    />
                                </td>
                            </tr>
                        ))
                    }

                    {
                        agentCars.length === 0 ?
                            <tr>
                                <td colSpan={3} className="text-center text-danger">
                                    ไม่พบข้อมูล
                                </td>
                            </tr>
                            : null
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default AgentCarSell