import React, { Component } from 'react';
import { Modal, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Link } from 'react-router-dom'; // Redirect
import classnames from 'classnames';
import axios from 'axios';
import {APIURL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL, PAGE20} from '../../../config/config';
import AgentFormModal from './AgentFormModal';
import Swal from "sweetalert2";
import { AlertSuccess } from '../../Alert/Alert';
import PreviewImage from '../../../views/PreviewImage';
import AgentByDate from '../AgentByDate';
import {CarSellUtil, CarsUtil} from "../../../utils/carSellUtil";
import AgentCarSell from "../AgentCarSell";
import Loading from "../../Loading";
import {connect} from "react-redux";
import {checkGroupPermission, getGroupPermission} from "../../../services/grouppermission";

class AgentList extends Component{
  constructor (props) {
    super(props)
    this.state = {
      activeTab: '1',
      isAgentModal: false,
      agent_list: [],
      agentSelected: null,
      preview_image_select: false,
      preview_image_url: '',
      page: 1,
      agent_all: 0,
      isLoading: false,
      user: JSON.parse(this.props.user),
      menuID: [5],
      grouppermissions: [],
    }

    this.loadAgentData = this.loadAgentData.bind(this);
    this.toggleAgentModal = this.toggleAgentModal.bind(this);
    this.onDeleteAgent = this.onDeleteAgent.bind(this);
    this.onEditAgent = this.onEditAgent.bind(this);
    this.togglePreviewImage = this.togglePreviewImage.bind(this);
    this.toggle = this.toggle.bind(this);
    this.loadGroupPermission = this.loadGroupPermission.bind(this);
  }

  loadGroupPermission() {
    let type = this.state.user.type
    let menuID = this.state.menuID
    getGroupPermission(type, menuID).then(res => {
      if(res.data.result === 'success') {
        this.setState({
          grouppermissions: res.data.grouppermissions
        })
      }
    })
  }

  componentDidMount () {
    CarsUtil.removeCarSelect()
    CarSellUtil.removeCarSellSelect()

    this.loadGroupPermission()
    this.loadAgentData()
  }

  togglePreviewImage() {
    this.setState({
      preview_image_select: !this.state.preview_image_select
    });
  }

  onRefresh(){
    this.refs.input_search.value = ''
    this.loadAgentData()
  }

  onSearch(){
    let search_text = this.refs.input_search.value

    if(search_text==='') {
      return;
    }

    if(search_text.length < 3){
      return;
    }

    axios.get( APIURL + '/agent/search/'+search_text )
      .then(res => {
        this.setState({agent_list: res.data})
      }).catch(error => {
      this.setState({agent_list: []})
    })
  }

  loadAgentData() {
    let page = this.state.page
    this.setState({isLoading: true})

    setTimeout(() => {
      axios.get( APIURL + '/agent/page/'+page).then(res => {
        // agents: agentsUniq,
        // agent_all: agent_all
        let agents = res.data.agents
        let agent_all = res.data.agent_all

        this.setState({
          agent_list: agents,
          agent_all: agent_all,
          isLoading: false
        })
      }).catch(error => {

      })
    }, 1200)

  }

  onDeleteAgent(agent) {
    const agent_id = agent.agent_id

    Swal.fire({
      title: 'ยืนยันการลบ '+agent.agent_fullname + ' ',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        axios.post( APIURL + '/agent2/delete/'+agent_id, agent).then(res => {
          if(res.status === 200) {
            AlertSuccess('ลบข้อมูลเรียบร้อยแล้ว')
            this.loadAgentData()
          }
        }).catch(error => {

        })
      }
    });

  }

  onEditAgent(agent){
    this.setState({
      agentSelected: agent
    }, () => this.toggleAgentModal('แก้ไข'))
  }

  toggleAgentModal(actionType) {
    if(actionType === 'บันทึก') {
      this.setState({
        agentSelected: null,
        isAgentModal: !this.state.isAgentModal,
        actionType: actionType,
      })
    } else {
      // agentSelected
      this.setState({
        isAgentModal: !this.state.isAgentModal,
        actionType: actionType
      }, () => {
        if(actionType === 'success') {
          this.loadAgentData()
        }
      })
    }
  }

  onPreviewSelected(url){
    this.setState({
      preview_image_url: url,
      preview_image_select: true
    });
  }


  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render () {

      // agent.agent_id
      const emptyData = <tr><td colSpan={8} className="text-center text-danger"> ไม่พบข้อมูล </td></tr>


    let agent_all = this.state.agent_all

    let start_row = 0
    let page = this.state.page

    if(agent_all > 0  && page > 0){
      start_row = Number(page-1) * PAGE20
    }

    let order_max = 0

    const agent_list = this.state.agent_list.map((agent, index) => {

      let image_url = '';

        if (agent.agent_file===null) {
        image_url = IMAGE_NOPHOTO_URL
      } else {
        if(agent.agent_file.includes('https')) {
          image_url = agent.agent_file;
        } else {
          image_url = IMAGE_FULL_URL+agent.agent_file;
        }
      }

        // old ลำดับจากมากไปน้อย
        // let order = Number(agent_all-start_row)-index // 16
        if(index === 0) {
          order_max = (Number(agent_all - start_row) - index)+ (page-1)*PAGE20;
        }

        let order_pre = Number(agent_all-start_row)-index - 1
        let order = order_max - order_pre
        if(order < 0){
          order = '-'
        }

      // console.log(agent_all, ' : ', start_row, ' : ', index, ' order=', order )


      return (
        <tr key={index}>
          <td className="text-center">{ order }</td>
          <td>
            <img
              src={image_url}
              style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
              onClick={()=>{
                if(agent.agent_file !=='')
                  this.onPreviewSelected(image_url)
              }}
              alt="เอกสาร"
            />
          </td>
          <td>
            ชื่อ-นามสกุล : { agent.agent_fullname } <br/>
            เลขประจำตัวประชาชน : { agent.agent_card_id } <br/>
            ที่อยู่ปัจจุบัน : { agent.agent_address } <br/>
            เบอร์โทรศัพท์ : { agent.agent_mobile }
            </td>
          <td>

           <AgentCarSell
               agent_id={agent.agent_id}
           />

          </td>
          <td className="text-center" >
            <Link to={'/agent/detail/?agent_id='+agent.agent_id} target='_blank' type="button"
                  className="btn btn-sm btn-info btn-md-width mb-2"
                  style={{width: 100}}
            >
              <i className="fa fa-list"/> รถที่ขาย
            </Link>

            {
              checkGroupPermission(5, this.state.grouppermissions).modified ? (
                  <button className="btn btn-sm btn-warning btn-md-width mb-2"
                          onClick={() => this.onEditAgent(agent)}
                          style={{width: 100}}
                  >
                    <i className="fa fa-edit"/> แก้ไข
                  </button>
              ) : null
            }

            { checkGroupPermission(5, this.state.grouppermissions).deleted ?
              <button className={"btn btn-sm btn-danger btn-md-width mb-2" }
                      onClick={() => this.onDeleteAgent(agent)}
                      style={{width: 100}}
                      disabled={agent.car_sell_id !== null}
              >
                <i className="fa fa-remove"/> ลบ
              </button> : null
            }
          </td>
        </tr>
      );
    })

    const agentList = this.state.agent_list.length > 0 ? agent_list : emptyData

    // let agent_all = this.state.agent_all
    let all_page = Math.ceil(agent_all/PAGE20)
    let page_array = Array.from(Array(all_page).keys());

    let tab_pages = page_array.map((page,index)=>{
      let page_tmp = page+1
      return (
          <li className="page-item" key={index}>
            <button className="page-link"  onClick={()=>{
                this.setState({
                  page: page_tmp
                },() => this.loadAgentData())
            }}>
              &nbsp;{page_tmp}&nbsp;
            </button>
          </li>
      )
    });

    return (
      <div className="col-md-12 mb-4">

        <AgentFormModal
          isAgentModal={this.state.isAgentModal}
          toggleAgentModal={this.toggleAgentModal}
          actionType={this.state.actionType}
          agentSelected={this.state.agentSelected}
        />

        <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
          <ModalBody>
            <PreviewImage
              imageUrl={this.state.preview_image_url}
            />
          </ModalBody>
        </Modal>

        <Loading isLoading={this.state.isLoading} />

        <Nav tabs>

          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              <i className="icon-options-vertical"/> ข้อมูลนายหน้า
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              <i className="icon-check"/> รายการรถที่ขายผ่านนายหน้า
            </NavLink>
          </NavItem>

        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <div className="card card-accent-success">
                  <div className="card-header card-customer">
                    <strong className="text-title"> ข้อมูลนายหน้า </strong>

                    <input
                      ref="input_search"
                      style={styles.inputSearch}
                      className="form-control"
                      placeholder="ค้นหาตามชื่อ หรือ เบอร์โทร"
                      type="text"
                    />

                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <button
                        className="btn btn-sm btn-head-bar mr-1"
                        onClick={this.onSearch.bind(this)}
                        style={styles.buttonHeight}
                    >
                      <i className="icon-search"/>ค้นหา
                    </button>

                    &nbsp;&nbsp;

                    <button className="btn btn-head-bar"
                            style={styles.buttonHeight}
                            onClick={this.onRefresh.bind(this)}
                    >
                      <i className="icon-refresh"/>
                    </button>

                    {
                      checkGroupPermission(5, this.state.grouppermissions).created ? (
                          <button type="button"
                                  onClick={() => this.toggleAgentModal('บันทึก')}
                                  className="btn btn-head-bar float-right mt-1"
                          >
                            <i className="icon-user-follow"/>&nbsp;เพิ่มข้อมูลนายหน้า
                          </button>
                      ) : null
                    }
                  </div>

                  <div className="card-block">
                    <table className="table table-responsive">
                      <thead>
                      <tr>
                        <th  style={{width: 15}} className="text-center">#</th>
                        <th  style={{width: 120}} className="text-center">รูปภาพ</th>
                        <th style={{width: '30%'}} className="text-center">ข้อมูลนายหน้า </th>
                        <th className="text-center" width={600}>ข้อมูลการขาย</th>
                        <th className="text-center" style={{width: 120}}>จัดการ</th>
                      </tr>
                      </thead>
                      <tbody>
                       {agentList}
                      </tbody>
                    </table>

                    <ul className= {"pagination d-flex flex-wrap pagination-sm" }>
                    {tab_pages}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>

          <TabPane tabId="2">
            <AgentByDate
                grouppermissions={this.state.grouppermissions}
            />
          </TabPane>
        </TabContent>
      </div>
    )
  }
}

const styles = {
  inputSearch: {
    marginLeft: 20,
    width: 300,
    display: 'inline'
  },

  buttonHeight: {
    height: 36,
    paddingTop:0,
    paddingBottom:0,
    display: 'inline',
    marginTop: '-0.35rem'
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  type: state.auth.type,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(AgentList)

