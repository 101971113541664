const routes = {
  '/': 'หน้าหลัก',
  '/dashboard': 'หน้าหลัก',
  '/login': 'Login',
  '/#/login': 'Login',
  '/components': 'Components',
  '/charts': 'Charts',
  '/components/buttons': 'Buttons',
  '/components/social-buttons': 'Social Buttons',
  '/components/cards': 'Cards',
  '/components/forms': 'Forms',
  '/components/modals': 'Modals',
  '/components/switches': 'Switches',
  '/components/tables': 'Tables',
  '/components/tabs': 'Tabs',
  '/icons': 'Icons',
  '/icons/font-awesome': 'Font Awesome',
  '/icons/simple-line-icons': 'Simple Line Icons',
  '/cars/list': 'ข้อมูลรถ',
  '/cars/albums': 'ข้อมูลอัลบั้มรถ',
  '/cars/freelance': 'ข้อมูลรถ-ฟรีแลนซ์',

  '/cars/new': 'เพิ่มข้อมูลรถ',
  '/cars/detail/': 'รายละเอียดรถ',
  '/cars/buy-contract': 'สัญญาซื้อรถเข้า',
  '/car-repair/list': 'ข้อมูลการซ่อมบำรุงรถ',
  '/car-repair/new': 'ข้อมูลการซ่อมบำรุง',
  '/car-history': 'ประวัติรถ',
  '/customer/list': 'ข้อมูลลูกค้า',
  '/customer/detail': 'รายละเอียดลูกค้า',
  '/sales/list': 'ข้อมูลการขายรถ',
  '/car-sell/detail/': 'รายละเอียดการขายรถ',
  '/debtors/list': 'ข้อมูลบัญชีลูกหนี้',
  '/debtors/payment-detail/': 'รายละเอียดผ่อนชำระ',
  '/carfinance/list': 'CarFinanceList',
  '/bank/list': 'ข้อมูลธนาคาร',
  '/maintenance/list': 'ข้อมูลศูนย์ซ่อมบำรุง',
  '/calendar/list': 'ปฏิทินเตือนความจำ',
  '/branch/list': 'ข้อมูลสาขา',
  '/account-list': 'ข้อมูลผู้ใช้งาน',
  '/cars/book': 'การจองรถ',
  '/cars/book-cancel': 'ยกเลิกการจอง',
  '/sales/buy/': 'การขายรถ',
  '/employee/list': 'ข้อมูลพนักงาน',

  '/expense/list': 'ค่าใช้จ่ายของเต็นท์รถ',
  '/expense/repair': 'ข้อมูลค่าใช้จ่ายซ่อมรถ',
  '/expense/installment': 'ค่างวด/ช่วยผ่อน',
  '/expense/car/other': 'ข้อมูลค่าใช้จ่ายอื่นๆ ของรถ',

  '/receipts/list': 'รายรับของเต็นท์รถ',
  '/receipts/income-tax-invoice': 'รายรับของเต็นท์รถ-ใบกำกับภาษี',
  '/car-reservation/list': 'ข้อมูลการจองรถ',
  '/car-reservation/tabs': 'ข้อมูลการจองรถ',
  '/sales/claim': 'ออกใบเคลม',
  '/car-claim/list': 'รายการเคลมรถ',
  '/sparepart': 'ข้อมูลอะไหล่',
  '/carin/all': 'ข้อมูลรถในเต็นท์',
  '/customer/findcar': 'ลูกค้าหารถ',
  '/customer/findcar/add': 'เพิ่มลูกค้าหารถ',
  '/calendar/manage': 'จัดการปฏิทินนัดหมาย',
  '/finance-pending/list': 'ข้อมูลรอจัดไฟแนนซ์',
  '/finance-pending/add/': 'เพิ่มข้อมูลจัดไฟแนนซ์',
  '/car-sell-pay-other/detail/': 'เพิ่มการชำระเงินอื่นๆของรถ',
  '/finance-tax/tabs': 'ใบกำกับไฟแนนซ์',
  '/car-receipt-ocpd': 'หลักฐานการรับเงิน ธุรกิจการขายรถยนต์ใช้แล้ว',

  '/customer-old/list': 'ข้อมูลลูกค้าเดิม',
  '/customer/storefront': 'ข้อมูลลูกค้าหน้าร้าน',

  '/report/expense/month': 'รายงานค่าใช้จ่ายรายเดือน',
  '/report/expense/monthat': 'รายงานค่าใช้จ่ายรายเดือน',

  '/car-check/': 'ประเมินทำสีรถ',
  '/carcheck/list': 'รายการประเมินทำสีรถ',
  '/customer/followup/tab': 'ติดตามลูกค้า',
  '/customer/followup/list': 'รายการติดตามลูกค้า',
  '/car-cost': 'ต้นทุนรายคัน',
  '/sales/estimated/revenue': 'ประมาณการรอเงินเข้า',
  '/agent/list': 'ข้อมูลนายหน้า',
  '/tax-document-tabs': 'เอกสารภาษี',
  '/trackinformation/tabs': 'ติดตามข้อมูลรถ',
  '/pricelist/tabs': 'price list',
  '/payment-voucher/': 'ใบสำคัญจ่าย',

  '/debtors/notice-detail/': 'รายละเอียดใบเตือนลูกค้า',
}
export default routes
