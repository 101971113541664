import React, { Component } from 'react';
import axios from 'axios'
import { APIURL } from '../../config/config'
import NumberFormat from "react-number-format"
import * as moment from 'moment'
import 'moment/locale/th'

// กด ดู จากปุ่มรถที่ขาย
class AgentCarDetail extends Component{
  constructor (props) {
    super(props)
    this.state = {
      agent_id: '',
      agent: null,
      agentCars: [],
      agentSum: 0
    }
    this.loadAgentById = this.loadAgentById.bind(this)
    this.loadAgentCarByAgentId = this.loadAgentCarByAgentId.bind(this)
  }

  componentDidMount() {
    const queryString = require('query-string');
    let agent = queryString.parse(this.props.location.search);
    let agent_id = agent.agent_id

    this.setState({
      agent_id: agent_id
    }, () => {
      this.loadAgentCarByAgentId(agent_id)
      this.loadAgentById(agent_id)
    })
  }

  loadAgentById(agent_id) {
    axios.get( APIURL + '/agent/id/'+agent_id).then(res => {
      this.setState({
        agent: res.data
      })
    }).catch(error => {

    })
  }

  loadAgentCarByAgentId(agent_id) {
    axios.get( APIURL + '/agent/cars/'+agent_id).then(res => {
      let agentSum = 0
      if(res.data.length > 0) {
        agentSum =  res.data.reduce((acc, next) => acc + next.car_external_commission, 0)
      }

      this.setState({
        agentCars: res.data,
        agentSum: agentSum
      })
    }).catch(error => {

    })
  }

  render() {
    const {
      agent,
      agentCars
    } = this.state

    const emptyData = <tr><td colSpan={5} className="text-center text-danger"> ไม่พบข้อมูล </td></tr>

    const agentCarsTr = this.state.agentCars.map((agentCar, index) => (
      <tr key={index}>
        <td className="text-center">{ index+1 }</td>
        <td className="text-center" width={400}>
          วันที่ขาย : { moment(agentCar.car_sell_date).locale('th').format('LL')}
          <br/>
          โดย : {agentCar.fullname}
        </td>
        <td width={400}>
          {agentCar.car_name} &nbsp;สี {agentCar.car_color_name} <br/>
          {
            agentCar.car_license_plate_new === '' ? null : (
                <>
                  ทะเบียนใหม่ : {agentCar.car_license_plate_new}&nbsp; จ.{agentCar.province_name_new}
                </>
            )
          }

          {
            agentCar.car_license_plate_new !== '' && agentCar.car_license_plate_old !== '' && agentCar.car_license_plate_new !== agentCar.car_license_plate_old ? <br/> : null
          }
          {
            agentCar.car_license_plate_new !== agentCar.car_license_plate_old && agentCar.car_license_plate_old !== '' ? (
                    <>
                      ทะเบียนเดิม : {agentCar.car_license_plate_old} &nbsp;จ.{agentCar.province_name_old}
                    </>
                )
                : null
          }
        </td>
        <td className="text-center">
          <center>
            <table className="table table-responsive">
              <tbody>
              <tr>
                <td className="text-right">ราคาขาย : </td>
                <td className="text-left">
                  <NumberFormat value={agentCar.real_sell_price}
                                displayType={'text'}
                                thousandSeparator={true}
                                //prefix={'฿'}
                  /> &nbsp;บาท
                </td>
              </tr>
              <tr>
                <td className="text-right">ยอดจัด : </td>
                <td className="text-left">
                  <NumberFormat
                    value={agentCar.rate_finance} displayType={'text'} thousandSeparator={true}
                    //prefix={'฿'}
                  /> &nbsp;บาท
                </td>
              </tr>
              </tbody>
            </table>
          </center>
        </td>
        <td className="text-center">
          <NumberFormat
            value={agentCar.car_external_commission} displayType={'text'} thousandSeparator={true}
            //prefix={'฿'}
          />&nbsp;บาท
        </td>
      </tr>
    ))

    const agentCarList = agentCars.length > 0 ? agentCarsTr : emptyData

    return (
      <div className="card">

        <div className="card-block">

          {agent === null ? null :
            <div className="col-sm-12 col-md-12 card card-inverse card-info">
              <div className="card-block">

                นายหน้า : {agent.agent_fullname} ({agent.agent_mobile}) <br/>
                ที่อยู่ : {agent.agent_address}

              </div>
            </div>
          }

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-8 pt-2">
                    <strong className="text-title">รายการรถที่ขายผ่านนายหน้า</strong>
                    </div>
                    <div className="col-4 text-right pt-2">
                      <strong className="text-title">รวม &nbsp;</strong>
                      <strong><NumberFormat
                        className="text-title"
                        displayType={'text'}
                        thousandSeparator={true}
                        value={this.state.agentSum}/>
                      </strong>
                      <strong className="text-title">&nbsp;บาท</strong>
                    </div>
                  </div>
                </div>
                <div className="card-block">
                  <table className="table table-responsive">
                    <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th className="text-center">วันที่ขาย/โดย</th>
                      <th className="text-center">ข้อมูลรถ</th>
                      <th className="text-center" width={400}>ข้อมูลการขาย</th>
                      <th className="text-center">ค่านายหน้า</th>
                    </tr>
                    </thead>
                    <tbody>
                     {agentCarList}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    )
  }

}

export default AgentCarDetail
